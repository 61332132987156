<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' ||
        $store.state.fastPermissoes.professor == 'S') &&
        $store.state.fastPermissoes.ativo == 'S'
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/professor')"
              >Modo professor</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Turmas</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>
    </div>
    <div class="fd-mode-adm-content mt-4">
      <div class="container">
        <div class="row">
          <div
            class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
          >
            <!-- fd-app-welcome -->
            <section class="fd-app-welcome text-left">
              <h1 class="aluno_font_color">
                <span>Turmas</span>
              </h1>
              <img
                :src="require('@/assets/images/form-effect-01.png')"
                style="max-height: 35px"
              >
            </section>
            <!-- /fd-app-welcome -->
          </div>
        </div>
      </div>
      <!-- conteudo principal -->
      <section class="container">
        <div v-if="exibeConteudoProfessor">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <div class="card shadow mb-4">
                <div class="card-body">
                  <h3 class="p-2">
                    Turmas
                  </h3>
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-3 mb-1">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Filtro por sigla da turma"
                          >
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-3 mb-1">
                          <select class="form-control form-control-sm">
                            <option>-- Filtrar por curso --</option>
                          </select>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-3 mb-1">
                          <select class="form-control form-control-sm">
                            <option>-- Filtrar por nível --</option>
                          </select>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4 mb-1 d-none">
                          <select class="form-control form-control-sm">
                            <option>-- Filtrar por plataforma --</option>
                          </select>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-3 mb-1">
                          <select class="form-control form-control-sm">
                            <option>-- Filtrar por turno --</option>
                            <option>
                              <span>Manhã</span>
                              <span>Tarde</span>
                              <span>Noite</span>
                              <span>Indefinido</span>
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 table-responsive mt-2">
                          <table class="table table-sm">
                            <thead class="thead-dark">
                              <tr>
                                <th class="text-center">
                                  <small class="font-weight-bold">Turma</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Curso</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Nível</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Turno</small>
                                </th>
                                <th class="text-center">
                                  <small
                                    class="font-weight-bold"
                                  >Finalizada</small>
                                </th>
                                <th class="text-center">
                                  <small
                                    class="font-weight-bold"
                                  >Abertura Turma</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Ativo</small>
                                </th>
                              </tr>
                            </thead>
                            <tbody
                              v-for="index in 1"
                              :key="index"
                            >
                              <tr
                                class="minhaclasse"
                                @click.prevent="ExibeAluno = true, exibeConteudoProfessor = false"
                              >
                                <td class="text-center">
                                  AM
                                </td>
                                <td class="text-center">
                                  Informática
                                </td>
                                <td class="text-center">
                                  Técnico
                                </td>
                                <td class="text-center">
                                  Manhã
                                </td>
                                <td class="text-center">
                                  Não
                                </td>
                                <td class="text-center">
                                  27/01/2021
                                </td>
                                <td class="text-center">
                                  Sim
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="ExibeAluno">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <div class="card shadow mb-4">
                <div class="card-body">
                  <h3 class="p-2">
                    Diario de Classe
                  </h3>
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                      <b-card no-body>
                        <b-tabs card>
                          <b-tab title="Diário de Classe">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center">
                                <div class="pl-1 pr-1">
                                  <small>Data:</small>
                                </div>
                                <div class="pl-1 pr-1">
                                  <input
                                    type="date"
                                    class="form-control form-control-sm"
                                  >
                                </div>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center">
                                <div class="pl-1 pr-1">
                                  <small>Carga Horaria total:</small>
                                </div>
                                <div class="pl-1 pr-1">
                                  <input
                                    type="number"
                                    style=" width:50px;"
                                    class="form-control form-control-sm"
                                  >
                                </div>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center">
                                <div class="pl-1 pr-1">
                                  <small>Aulas dadas:</small>
                                </div>
                                <div class="pl-1 pr-1">
                                  <input
                                    type="number"
                                    style=" width:50px;"
                                    class="form-control form-control-sm"
                                  >
                                </div>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center">
                                <div class="pl-1 pr-1">
                                  <small>Horas Aula:</small>
                                </div>
                                <div class="pl-1 pr-1">
                                  <input
                                    type="number"
                                    style=" width:50px;"
                                    class="form-control form-control-sm"
                                  >
                                </div>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-12 table-responsive mt-2">
                                <table class="table table-sm table-striped">
                                  <thead class="thead-dark">
                                    <tr>
                                      <th class="text-center">
                                        <small
                                          class="font-weight-bold"
                                        >Número</small>
                                      </th>
                                      <th class="text-center">
                                        <small
                                          class="font-weight-bold"
                                        >Matrícula</small>
                                      </th>
                                      <th class="text-center">
                                        <small
                                          class="font-weight-bold"
                                        >Nome</small>
                                      </th>
                                      <th class="text-center">
                                        <small
                                          class="font-weight-bold"
                                        >P1: N</small>
                                      </th>
                                      <th class="text-center">
                                        <small
                                          class="font-weight-bold"
                                        >P2: N</small>
                                      </th>
                                      <th class="text-center">
                                        <small
                                          class="font-weight-bold"
                                        >P3: N</small>
                                      </th>
                                      <th class="text-center">
                                        <small
                                          class="font-weight-bold"
                                        >% Pres.</small>
                                      </th>
                                      <th class="text-center">
                                        <small
                                          class="font-weight-bold"
                                        >Total Faltas</small>
                                      </th>
                                      <th class="text-center">
                                        <small
                                          class="font-weight-bold"
                                        >Faltas Módulo</small>
                                      </th>
                                      <th class="text-center">
                                        <small
                                          class="font-weight-bold"
                                        >Situação</small>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="index in 1"
                                      :key="index"
                                    >
                                      <td class="text-center">
                                        01
                                      </td>
                                      <td class="text-center">
                                        032540023665
                                      </td>
                                      <td class="text-center">
                                        José Santos
                                      </td>
                                      <td class="text-center">
                                        <input type="checkbox">
                                      </td>
                                      <td class="text-center">
                                        <input type="checkbox">
                                      </td>
                                      <td class="text-center">
                                        <input type="checkbox">
                                      </td>
                                      <td class="text-center">
                                        100%
                                      </td>
                                      <td class="text-center">
                                        0
                                      </td>
                                      <td class="text-center">
                                        0
                                      </td>
                                      <td class="text-center">
                                        Ativo
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </b-tab>
                          <b-tab title="Notas" />
                          <b-tab title="Emissão Diario de Classe" />
                          <b-tab title="Liberar para Secretaria" />
                          <b-tab title="Plano de Ensino" />
                          <b-tab title="Programação de aulas" />
                        </b-tabs>
                      </b-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <div class="card shadow mb-4">
                <div class="card-body">
                  <h3 class="p-2">
                    Alunos
                  </h3>
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                      <b-card no-body>
                        <b-tabs card>
                          <b-tab
                            title="Alunos"
                            active
                          >
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-3 mb-1">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder="Matricula"
                                  >
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 mb-1">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    placeholder="Nome"
                                  >
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 mb-1">
                                  <select class="form-control form-control-sm">
                                    <option>-- Situação --</option>
                                  </select>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 table-responsive mt-2">
                                  <table class="table table-sm table-striped">
                                    <thead class="thead-dark">
                                      <tr>
                                        <th class="text-center">
                                          <small
                                            class="font-weight-bold"
                                          >Número</small>
                                        </th>
                                        <th class="text-center">
                                          <small
                                            class="font-weight-bold"
                                          >Matrícula</small>
                                        </th>
                                        <th class="text-center">
                                          <small
                                            class="font-weight-bold"
                                          >Nome</small>
                                        </th>
                                        <th class="text-center">
                                          <small
                                            class="font-weight-bold"
                                          >Situação</small>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="index in 1"
                                        :key="index"
                                      >
                                        <td class="text-center">
                                          01
                                        </td>
                                        <td class="text-center">
                                          032540023665
                                        </td>
                                        <td class="text-center">
                                          José Santos
                                        </td>
                                        <td class="text-center">
                                          Ativo
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </b-tab>
                          <b-tab title="Enviar Arquivos" />
                          <b-tab title="Mensagens" />
                        </b-tabs>
                      </b-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="mt-5 mb-5">
              <button
                class="btn btn-red-hollow"
                @click="exibeConteudoProfessor = true, exibeAluno = false"
              >
                Voltar
              </button>
            </div>
          </div>
        </div>
      </section>
      <!-- conteudo principal -->

      <div
        v-if="exibeConteudoProfessor"
        class="container"
      >
        <div class="mt-5 mb-5">
          <a
            class="btn btn-red-hollow"
            :href="'/plataforma/' + $route.params.id_plataforma + '/professor'"
            @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/professor')"
          >
            <b-icon-arrow-return-left />
            Voltar
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeInternoProfessorTurmas",
  components: {
  },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      exibeConteudoProfessor: true,
      exibeAluno: false,
    };
  },
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'professor')
        .then(() => {
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {},
};
</script>

<style scope>
/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz editor Quill ficar bugada para edição */
[contenteditable="true"] {
  display: block;
}
/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}
.fd-app-curso-aulas .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item,
.fd-app-meus-cursos .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item {
  font-family: initial;
}

.ql-container {
  height: auto !important;
}
.minhaclasse {
cursor: pointer;
}
.minhaclasse:hover {
  background-color: #eee;
}
</style>
